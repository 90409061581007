.labelled-outline {
    &__fieldset {
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin: 0px;
        padding: 0px;
    }

    &__legend {
        margin-left: 14px;
        color: #9e9e9e;
        font-size: 14px;
    }
}