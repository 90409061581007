.notifications {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
    &.true {
        padding: 15px;
    }
    &.false {
        padding: 0px;
    }
}