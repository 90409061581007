.layout-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    box-sizing: border-box;
    padding: 0 25px;
    width: 100%;

    &--text {
        padding: 25px;
    }

    &-pad0 {
        padding: 0
    }
}