.profile-page {
  &__version {
    text-align: center;
    padding-top: 50px;
    font-size: 11px;
    color: #ccc;

    &-link {
      color: #ccc;
      text-decoration: none;
    }
  }
}