body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  line-height: 1.5;
}

div, p {
  font-family: Poppins, sans-serif;
}

p, ul, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul {
  padding: 0 1rem;
}

ol {
  padding: 0;
}

h1, h2, h3, h4, h5 {
  line-height: 1.15;
}

h1, .h1 {
  font-weight: bold;
  font-size: 1.875rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.438rem;
}

h4, .h4 {
  font-size: 1.313rem;
}

h5, .h5 {
  font-size: 1.125rem;
  font-weight: normal;
}

h6, .h6 {
  font-size: 1rem;
}

b {
  font-weight: bold;
}

button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
}

input {
  font: inherit;
}